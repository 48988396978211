import * as React from 'react';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link as ExtLink,
  ListItem,
  Skeleton,
  UnorderedList,
  Flex,
} from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
// import { API, Auth } from 'aws-amplify';
import { unwrapResult } from '@reduxjs/toolkit';

import AccountsMenu from '../../menus/AccountsMenu';
import { useAppDispatch } from '../../../state/createStore';
import { getAccountProjects } from '../../../state/project/projectSlice';
import './sidemenu.css';
import 'react-toastify/dist/ReactToastify.css';

export default function SideMenu(): JSX.Element {
  const { currentAccount } = useSelector((state: any) => state.account);
  const { projects } = useSelector((state: any) => state.projects);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (currentAccount) {
      dispatch(getAccountProjects(currentAccount?.accountId))
        .then(unwrapResult)
        .catch((error) => {
          toast.error(`Could not get projects ${error.message}`);
        });
    }
  }, [currentAccount, dispatch]);

  // const getStripeLink = async () => {
  //   try {
  //     const token = (await Auth.currentSession())
  //       .getAccessToken()
  //       .getJwtToken();
  //     const myInit = {
  //       body: {
  //         return_url: window.location.href,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     const res = await API.post(
  //       'openscreen',
  //       `billing/session/${currentAccount.accountId}`,
  //       myInit
  //     );
  //     window.location.href = res.redirectUrl;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <Box
      bgColor="#fff"
      pos={{ base: 'relative', md: 'sticky' }}
      top={{ base: '0rem', md: '7.7rem' }}
      maxW="350px"
      maxH={{ base: '85vh', md: '10000px' }}
      w={{ base: '100vw', md: '25vw' }}
    >
      <Flex w="100%" margin="auto" maxW="400px">
        <AccountsMenu />
      </Flex>
      <UnorderedList paddingLeft="15px" paddingTop="5px" styleType="none">
        <ListItem fontWeight="bold">
          <Link
            activeClassName="styled-link-active"
            className="styled-link"
            to="/app"
          >
            Dashboard
          </Link>
        </ListItem>
      </UnorderedList>
      <Accordion
        boxShadow={{ base: 'none', md: 'xl' }}
        paddingTop="15px"
        paddingBottom="1.5rem"
        width="100%"
        maxW="400px"
        defaultIndex={[0, 1, 2, 3]}
        allowMultiple
      >
        <AccordionItem
          border="none"
          borderBottom="1px"
          borderBottomColor="gray.100"
          paddingX="15px"
        >
          <h2>
            <AccordionButton fontWeight="bold">
              <Box flex="1" textAlign="left">
                My Projects
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList spacing={1} styleType="none">
              <ListItem marginLeft="10px">
                <Link
                  activeClassName="styled-link-active"
                  className="styled-link"
                  to="/app/project/create"
                >
                  Create A New Project
                </Link>
              </ListItem>
              <ListItem marginLeft="10px">
                <Link
                  activeClassName="styled-link-active"
                  className="styled-link"
                  to="/app/projects/manage"
                >
                  Manage Projects
                </Link>
              </ListItem>
              <Skeleton isLoaded={!!projects}>
                {projects.map(
                  (
                    e: {
                      projectId: any;
                      name:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal;
                    },
                    i: number
                  ) => {
                    if (i <= 4) {
                      return (
                        <ListItem
                          key={e.projectId}
                          marginY="5px"
                          marginLeft="10px"
                        >
                          <Link
                            activeClassName="styled-link-active"
                            className="styled-link"
                            to={`/app/project/view/${e.projectId}`}
                          >
                            {e.name}
                          </Link>
                        </ListItem>
                      );
                    }
                    return <></>;
                  }
                )}
              </Skeleton>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          border="none"
          borderBottom="1px"
          borderBottomColor="gray.100"
          paddingX="15px"
        >
          <h2>
            <AccordionButton fontWeight="bold">
              <Box flex="1" textAlign="left">
                Reporting & Analytics
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList spacing={1} styleType="none">
              <ListItem marginLeft="10px">
                <Link
                  activeClassName="styled-link-active"
                  className="styled-link"
                  to="/app/reporting-and-analytics/scan-data"
                >
                  QR Code & Scan Reporting
                </Link>
              </ListItem>
              <ListItem marginLeft="10px">
                <Link
                  activeClassName="styled-link-active"
                  className="styled-link"
                  to="/app/reporting-and-analytics/asset-and-contact-data"
                >
                  Asset & Contact Data
                </Link>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem
          border="none"
          borderBottom="1px"
          borderBottomColor="gray.100"
          paddingX="15px"
        >
          <h2>
            <AccordionButton fontWeight="bold">
              <Box flex="1" textAlign="left">
                Account Settings
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList spacing={1} styleType="none">
              <ListItem marginLeft="10px">
                <Link
                  activeClassName="styled-link-active"
                  className="styled-link"
                  to="/app/account"
                >
                  Account
                </Link>
              </ListItem>
              <ListItem marginLeft="10px">
                <Link
                  activeClassName="styled-link-active"
                  className="styled-link"
                  to="/app/account/manage/users"
                >
                  Users
                </Link>
              </ListItem>
              <ListItem marginLeft="10px">
                <Link
                  activeClassName="styled-link-active"
                  className="styled-link"
                  to="/app/account/subscription-plan"
                >
                  Subscription Plan
                </Link>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none" paddingX="15px">
          <h2>
            <AccordionButton fontWeight="bold">
              <Box flex="1" textAlign="left">
                Documentation & Tools
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <UnorderedList spacing={1} styleType="none">
              <ListItem marginLeft="10px">
                <ExtLink
                  className="styled-link"
                  href="https://docs.openscreen.com/docs/developer-portal/node-sdk/getting-started/"
                >
                  Getting Started
                </ExtLink>
              </ListItem>
              <ListItem marginLeft="10px">
                <ExtLink
                  className="styled-link"
                  href="https://docs.openscreen.com/docs/developer-portal/node-sdk/getting-started/"
                >
                  Node SDK
                </ExtLink>
              </ListItem>
              <ListItem marginLeft="10px">
                <ExtLink
                  className="styled-link"
                  href="https://docs.openscreen.com/docs/"
                >
                  REST API
                </ExtLink>
              </ListItem>
              <ListItem marginLeft="10px">
                <ExtLink
                  className="styled-link"
                  href="https://docs.openscreen.com/docs/developer-portal/node-sdk/code-locker/overview/"
                >
                  Code Locker
                </ExtLink>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <ToastContainer theme="colored" />
    </Box>
  );
}
