import {
  Button,
  useDisclosure,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  HStack,
  Input,
} from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import * as React from 'react';
import { Auth, API } from 'aws-amplify';
import { BellIcon } from '@chakra-ui/icons';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch } from '../../../state/createStore';
import { setAccount } from '../../../state/account/account';

const InvitationsModal = ({ setPendingInvitation }: any): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [invitation, setInvitation] = React.useState({
    companyName: '',
    accountId: '',
  });
  const [email, setEmail] = React.useState('');
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);

  const getInvite = async (inviteId: string) => {
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const myInit = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await API.get(
        'openscreen',
        `invitations/${inviteId}`,
        myInit
      );
      setInvitation(res.accountInvitation);
    } catch (error) {
      console.log(error);
    }
  };

  const acceptInvite = async () => {
    const invitationId = localStorage.getItem('invitationTokenid');
    setIsLoading(true);
    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const myInit = {
        body: {
          email,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await API.post(
        'openscreen',
        `invitations/${invitationId}/users`,
        myInit
      );
      localStorage.removeItem('invitationTokenid');
      toast.success(
        `You've accepted an invitation to ${
          invitation?.companyName || invitation?.accountId
        }`
      );
      setIsLoading(false);
      dispatch(setAccount(res.account));
      onClose();
      setPendingInvitation(false);
    } catch (error) {
      toast.error(
        `There was an error accepting your invite to ${
          invitation?.companyName || invitation?.accountId
        }. ${error.message}`
      );
      setIsLoading(false);
      console.log(error);
    }
  };

  const declineInvite = async () => {
    const invitationId = localStorage.getItem('invitationTokenid');
    setIsLoading(true);

    try {
      const token = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      const myInit = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await API.del('openscreen', `invitations/${invitationId}`, myInit);
      localStorage.removeItem('invitationTokenid');
      toast.success(
        `You've successfully declined an invitation to ${
          invitation?.companyName || invitation?.accountId
        }`
      );
      onClose();
      setPendingInvitation(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      localStorage.removeItem('invitationTokenid');
      console.log(error);
    }
  };

  React.useEffect(() => {
    const invitationTokenId = localStorage.getItem('invitationTokenid');
    if (invitationTokenId) {
      getInvite(invitationTokenId);
    }
  }, []);

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <IconButton
        variant="ghost"
        colorScheme="black"
        aria-label="Check for invitations"
        fontSize="20px"
        color="red"
        isLoading={isLoading}
        onClick={onOpen}
        icon={<BellIcon />}
        marginX="5px"
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pending Invitations</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
              <Text marginBottom="5px">
                Any pending invitations to accounts will be shown here
              </Text>
            </Flex>
            <Input
              placeholder={`Recipient's Email`}
              onChange={handleChange}
              value={email}
            />
            <Flex marginY="20px" align="center" justify="space-between">
              <Text>{invitation?.companyName || invitation?.accountId}</Text>
              <HStack justify="center">
                <Button
                  bgColor="#4fc1ff"
                  color="#fff"
                  _hover={{
                    bgColor: '#000',
                  }}
                  borderRadius="15px"
                  onClick={acceptInvite}
                >
                  Accept
                </Button>
                <Button
                  bgColor="#000"
                  color="#fff"
                  _hover={{
                    bgColor: '#4fc1ff',
                  }}
                  borderRadius="15px"
                  onClick={declineInvite}
                >
                  Decline
                </Button>
              </HStack>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ToastContainer theme="colored" />
    </>
  );
};

export default InvitationsModal;
