import { Box, Flex, Stack } from '@chakra-ui/react';
import * as React from 'react';
import SideMenu from '../nav/sidemenu/sidemenu';
import TopNav from '../nav/top-nav/top-nav';
import './layout.css';

const Layout = ({ children }: any) => {
  return (
    <Stack bgColor="#e5ecf2">
      <TopNav />
      <Flex bgColor="#e5ecf2">
        <Box display={{ base: 'none', md: 'block' }} id="side-nav" maxW="400px">
          <SideMenu />
        </Box>
        <Box width="100%">{children}</Box>
      </Flex>
    </Stack>
  );
};
export default Layout;
