import * as React from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector((state: any) => state.user);

  function isEmpty(obj: any) {
    if (obj) {
      return Object.keys(obj).length === 0;
    }
    return null;
  }

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        // dispatch(setInitialUser(res.attributes));
      })
      .catch((e) => {
        navigate('/signin');
        return null;
      });
  }, [user]);

  const isBrowser = typeof window !== 'undefined';
  if (isEmpty(user) && isBrowser) {
    navigate('/signin');
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
