import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { signOut } from '../../state/user/userSlice';
import { resetKey } from '../../state/apiKey/apiKeySlice';
import { removeAllProjects } from '../../state/project/projectSlice';
import MobileSideMenu from '../nav/sidemenu/mobile/mobileSideMenu';

const ProfileMenu = () => {
  const { user } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  // const [user, setUser] = React.useState(null);

  const logOut = async () => {
    try {
      dispatch(removeAllProjects());
      dispatch(resetKey());
      dispatch(signOut());
    } catch (error) {
      toast.error(`Could not sign out ${error.message}`);
    }
  };

  return (
    <>
      <ToastContainer theme="colored" />

      <Menu>
        <MenuButton
          maxH="30px"
          as={Button}
          bgColor="#fff"
          fontWeight="normal"
          fontSize="13pt"
          alignContent="center"
          aria-label="Options"
        >
          <Flex align="center">
            <Text display={{ base: 'none', md: 'flex' }}>{user?.email}</Text>
            <ChevronDownIcon width={7} height={7} />
          </Flex>
        </MenuButton>
        <MenuList>
          <Box
            marginTop="10px"
            display={{ base: 'initial', md: 'none' }}
            minW="100vw"
          >
            <MobileSideMenu />
          </Box>
          <MenuItem display={{ base: 'none', md: 'initial' }} onClick={logOut}>
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default ProfileMenu;
