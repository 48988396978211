import * as React from 'react';
import { Link as ExtLink, MenuItem, Stack } from '@chakra-ui/react';
import { Link } from 'gatsby';
import AccountsMenu from '../../../menus/AccountsMenu';

const MobileSideMenu = (): JSX.Element => {
  return (
    <Stack padding="20px" minW="100vw">
      <AccountsMenu />
      <MenuItem>
        <Link to="/app/project/create">Create A New Project</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/app/projects/manage">Manage Projects</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/app/account">Account</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/app/account/manage/users">Users</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/app/account/subscription-plan">Subscription Plan</Link>
      </MenuItem>
      <MenuItem>
        <ExtLink href="https://docs.openscreen.com">Docs</ExtLink>
      </MenuItem>
    </Stack>
  );
};

export default MobileSideMenu;
