import * as React from 'react';
import { Link } from 'gatsby';
import { Flex } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import ProfileMenu from '../../menus/profileMenu';
import InvitationsModal from '../../modals/invitation/invitation';

const TopNav = (): JSX.Element => {
  const [pendingInvitation, setPendingInvitation] = React.useState(false);

  React.useEffect(() => {
    const invitationTokenId = localStorage.getItem('invitationTokenid');
    if (invitationTokenId) {
      setPendingInvitation(true);
    }
  }, []);

  return (
    <Flex
      zIndex={3}
      position="sticky"
      top="0px"
      padding="20px"
      maxH="100px"
      bgColor="#fff"
      align="center"
      borderBottom="1px"
      borderColor="gray.300"
      justify="space-between"
      marginBottom="20px"
    >
      <Link to="/app">
        <StaticImage
          width={230}
          objectFit="contain"
          src="https://openscreen-web-assets-prod.s3.amazonaws.com/2020-09-27+Openscreen+Logo.png"
          alt="logo"
        />
      </Link>
      <Flex align="center">
        {pendingInvitation ? (
          <InvitationsModal setPendingInvitation={setPendingInvitation} />
        ) : (
          <></>
        )}
        <ProfileMenu />
      </Flex>
    </Flex>
  );
};

export default TopNav;
