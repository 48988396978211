import * as React from 'react';
import { Router } from '@reach/router';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
// import { createHistory } from '@reach/router';
import PrivateRoute from '../components/privateRoute/privateRoute';
import NotFound from './404';
import DashPage from './app/dashboard';
import AccountProfilePage from './app/accountProfile';
import ViewProjectPage from './app/project/template';
import Layout from '../components/layout';
import { setInitialUser } from '../state/user/userSlice';
import ManageAccountPage from './app/project/manage';
import ManageAccountUsersPage from './app/account/manageUsers';
import ScanDataPage from './app/reporting-and-analytics/scanDataPage';
import AssetContactDataPage from './app/reporting-and-analytics/assetContactData';
import SubscriptionPlanPage from './app/account/subscription-plan';
import CreateAccountPage from './app/account/createAccount';
import CreateProjectPage from './app/project/create';

import 'react-toastify/dist/ReactToastify.css';

const App = (): JSX.Element => {
  // const history = createHistory(window);
  const [isClient, setClient] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        dispatch(setInitialUser(res.attributes));
      })
      .catch((e) => {
        navigate('/signin');
        return null;
      });
  }, []);

  React.useEffect(() => {
    setClient(true);
  }, []);

  if (!isClient) return null;
  // React.useEffect(() => {
  //   console.log('ROUTE CHANGED');
  // }, [history]);

  return (
    <Layout>
      <Router basepath="/app">
        <PrivateRoute path="/" component={DashPage} />
        <PrivateRoute path="/account" component={AccountProfilePage} />
        <PrivateRoute path="/account/create" component={CreateAccountPage} />
        <PrivateRoute path="/project/create" component={CreateProjectPage} />
        <PrivateRoute
          path="/project/view/:projectId"
          component={ViewProjectPage}
        />
        <PrivateRoute
          path="/account/manage/users"
          component={ManageAccountUsersPage}
        />
        <PrivateRoute path="/projects/manage" component={ManageAccountPage} />
        <PrivateRoute
          path="/reporting-and-analytics/scan-data"
          component={ScanDataPage}
        />
        <PrivateRoute
          path="/reporting-and-analytics/asset-and-contact-data"
          component={AssetContactDataPage}
        />
        <PrivateRoute
          path="/account/subscription-plan"
          component={SubscriptionPlanPage}
        />

        <NotFound path="/404" default />
      </Router>
    </Layout>
  );
};

export default App;
