import * as React from 'react';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { navigate } from 'gatsby';
import {
  Menu,
  MenuButton,
  MenuList,
  Text,
  MenuItem,
  Spinner,
  Flex,
  Avatar,
  WrapItem,
  HStack,
  Box,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../state/createStore';
import {
  getUserAccounts,
  setCurrentAccount,
} from '../../state/account/account';

const AccountsMenu = (): JSX.Element => {
  const { account } = useSelector((state: any) => state.account);
  const { currentAccount } = useSelector((state: any) => state.account);
  const { user, userId } = useSelector((state: any) => state.user);

  const dispatch = useAppDispatch();
  const testDispatch = useDispatch();
  const switchAccounts = (e: any) => {
    const newSelectedAccount = JSON.parse(e.target.value);
    testDispatch(setCurrentAccount(newSelectedAccount));
    const url = window.location.pathname;
    if (url.toLowerCase() !== '/app') {
      navigate('/app');
    }
  };

  React.useEffect(() => {
    if (userId) {
      dispatch(getUserAccounts(userId))
        .then(unwrapResult)
        .catch((e: any) => {
          console.error(e);
        });
    }
  }, [dispatch, userId]);

  const createAccountPage = () => {
    navigate('/app/account/create');
  };

  return (
    <Menu placement="bottom">
      <motion.div
        style={{
          width: '100%',
        }}
        whileHover={{
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        }}
      >
        <MenuButton
          disabled={account.length === 0}
          as={Flex}
          rightIcon={<ChevronDownIcon />}
          w="100%"
          padding="20px"
          _hover={{
            cursor: 'pointer',
          }}
          _disabled={{
            cursor: 'default',
          }}
        >
          <HStack spacing={6}>
            {account.length !== 0 ? (
              <Flex
                align="center"
                justify="space-between"
                w="100%"
                maxW="400px"
              >
                <Flex align="center">
                  <WrapItem paddingX="10px">
                    <Avatar width="4rem" height="4ren" src="broken" />
                  </WrapItem>
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="14pt">
                      {user?.given_name} {user?.family_name}
                    </Text>
                    <Text
                      textColor="#a8a8a8"
                      textOverflow="ellipsis"
                      fontSize="12pt"
                      overflow="hidden"
                    >
                      {/* {currentAccount.companyName || currentAccount.accountId} */}
                      {currentAccount?.companyName || currentAccount?.accountId}
                    </Text>
                  </Flex>
                </Flex>
                <ChevronDownIcon width="25px" height="25px" />
              </Flex>
            ) : (
              <Flex h="75px" margin="auto">
                <Spinner w="30px" h="30px" />
              </Flex>
            )}
          </HStack>
        </MenuButton>
      </motion.div>
      <MenuList
        overflow="hidden"
        textOverflow="ellipsis"
        fontSize="12pt"
        whiteSpace="nowrap"
      >
        {account?.map((e: any) => {
          return (
            <MenuItem
              onClick={switchAccounts}
              value={JSON.stringify(e)}
              name={e.companyName}
              key={e.accountId}
            >
              {/* {currentAccount.companyName || currentAccount.accountId} */}
              {e?.companyName
                ? `${e?.companyName.substring(0, 4)}:${e?.accountId}`
                : e?.accountId}
            </MenuItem>
          );
        })}
        <MenuItem>
          <Box
            _hover={{
              cursor: 'pointer',
            }}
            onClick={createAccountPage}
            border="none"
            bgColor="transparent"
            fontWeight="bold"
          >
            <HStack spacing={3}>
              <AddIcon />
              <Text>Create a New Account</Text>
            </HStack>
          </Box>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default AccountsMenu;
